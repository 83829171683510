<template>
  <v-card>
    <v-card-text>
      <v-row>
        <v-col>
          <material-recurring-payments-info :info="info" />
        </v-col>
        <v-col
          v-if="info.autoPayment"
          cols="auto"
        >
          <v-row>
            <v-col>
              <v-btn
                rounded
                depressed
                outlined
                color="primary"
              >
                <span
                  v-t="'payment.editRecurringPayments'"
                  style="white-space: normal;"
                />
              </v-btn>

              <v-alert
                color="grey lighten-4"
                dense
                :icon="infoIcon"
                prominent
                class="mt-4"
              >
                <small>
                  <material-recurring-payments-edit-warning-info />
                </small>
              </v-alert>
            </v-col>
            <v-col>
              <material-recurring-payments-cancel-modal-window />
              <v-alert
                color="grey lighten-4"
                dense
                :icon="infoIcon"
                prominent
                class="mt-4"
              >
                <small v-t="'payment.disablingRecurringPaymentsWarning'" />
              </v-alert>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  RECURRING_PAYMENTS_ENABLED,
  RECURRING_PAYMENTS_DISABLED,
  INFO_ICON
} from '../../../constants/app'

export default {
  props: {
    info: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    infoIcon: INFO_ICON,
    recurringPaymentsEnabled: RECURRING_PAYMENTS_ENABLED,
    recurringPaymentsDisabled: RECURRING_PAYMENTS_DISABLED
  })
}
</script>
